import React, { FC } from 'react';
import classNames from 'classnames';

import { Container } from 'layout';
import Typography from 'common/Typography';

import { NutritionProps } from './models';

import './Nutrition.scss';

const Nutrition: FC<NutritionProps> = ({ description, title, analyseElement }) => (
  <div className="nutrition">
    <Container gutter>
      <Typography className="nutrition__heading" as="h2" dangerouslySetInnerHTML={title} />
      <div className="nutrition__analyse-wrapper">
        {analyseElement.map(({ title: analyseTitle, firstAmount, secondAmount, isColor }) => (
          <div
            className={classNames('nutrition__analyse', {
              'nutrition__analyse--active': isColor ? isColor[0] : null,
            })}
            key={analyseTitle}
          >
            <p className="nutrition__analyse-title">{analyseTitle}</p>
            <Typography
              dangerouslySetInnerHTML={firstAmount}
              className={classNames('nutrition__analyse-first-amount', {
                'nutrition__analyse-first-amount--active': isColor ? isColor[0] : null,
              })}
            />
            <Typography
              dangerouslySetInnerHTML={secondAmount}
              className="nutrition__analyse-second-amount"
            />
          </div>
        ))}
      </div>
      <Typography dangerouslySetInnerHTML={description} className="nutrition__footnote" />
    </Container>
  </div>
);

export default Nutrition;
