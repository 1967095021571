import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import FadeIn from 'framers/FadeIn';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Banner from 'components/Banner';
import ContentElement from 'components/ContentElement';
import Nutrition from 'components/Nutrition';
import TimeDetails from 'components/TimeDetails';

import './Recipe.scss';

const RecipeDetailPage: FC<RecipeDetailPageTypes.RecipeDetailProps> = ({
  data: {
    recipeDetail: {
      urls,
      seo,
      banner,
      articleContent,
      ingredientsDetails,
      timeDetails,
      nutritionDetails,
      instructionsDetails,
      instructionsDetailsOptional,
      ingredientsDetailsOptional,
    },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout>
      <Helmet htmlAttributes={{ class: 'smoth-scroll' }} />
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner {...banner} />
      <ContentElement contentElement={articleContent} />
      <FadeIn>
        {timeDetails.length
          ? timeDetails.map((detail) => <TimeDetails {...detail} key={detail.fibreText} />)
          : null}
      </FadeIn>
      <FadeIn>
        <div className="recipe-detail-page__ingredients">
          <Container gutter>
            <Typography
              dangerouslySetInnerHTML={ingredientsDetails}
              className="recipe-detail-page__ingredients-text"
            />
            <Typography
              dangerouslySetInnerHTML={ingredientsDetailsOptional}
              className="recipe-detail-page__ingredients-text-optional"
            />
            <Typography
              dangerouslySetInnerHTML={instructionsDetails}
              className="recipe-detail-page__instruction-text"
            />
            <Typography
              dangerouslySetInnerHTML={instructionsDetailsOptional}
              className="recipe-detail-page__instruction-text-optional"
            />
          </Container>
        </div>
      </FadeIn>
      <FadeIn>
        {nutritionDetails.map((el) => (
          <Nutrition {...el} key={el.description} />
        ))}
      </FadeIn>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String]) {
    recipeDetail(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      banner {
        ...BannerFragment
      }
      articleContent {
        ...ContentElementFragment
      }
      ingredientsDetails
      instructionsDetails
      ingredientsDetailsOptional
      instructionsDetailsOptional
      nutritionDetails {
        description
        title
        analyseElement {
          firstAmount
          secondAmount
          title
          isColor
        }
      }
      timeDetails {
        chillTimeText
        fibreText
        prepTimeText
        totalTimeText
        image {
          ...TransparentImageStructureFragment
        }
      }
    }
  }
`;

export default RecipeDetailPage;
