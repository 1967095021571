import React, { FC } from 'react';

import { Container } from 'layout';
import Image from 'common/Image';
import Typography from 'common/Typography';

import { TimeDetailsProps } from './models';

import './TimeDetails.scss';

const TimeDetails: FC<TimeDetailsProps> = ({
  image,
  fibreText,
  prepTimeText,
  chillTimeText,
  totalTimeText,
}) => (
  <div className="time-details">
    <Container gutter>
      <div className="time-details__heading-wrapper">
        {image ? <Image {...image.imageStructure} className="time-details__icon" /> : null}
        {fibreText ? <p className="time-details__heading">{fibreText}</p> : null}
      </div>
      <div className="time-details__wrapper">
        {prepTimeText ? (
          <div className="time-details__text-wrapper">
            <Typography dangerouslySetInnerHTML={prepTimeText} />
          </div>
        ) : null}
        {chillTimeText ? (
          <div className="time-details__text-wrapper">
            <Typography dangerouslySetInnerHTML={chillTimeText} />
          </div>
        ) : null}
        {totalTimeText ? (
          <div className="time-details__text-wrapper time-details__text-wrapper--no-border">
            <Typography dangerouslySetInnerHTML={totalTimeText} />
          </div>
        ) : null}
      </div>
    </Container>
  </div>
);

export default TimeDetails;
